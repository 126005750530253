#mc_embed_signup {
    @apply .w-full;

    // increase selector specificity for
    // in-line errors
    div.mc-field-group {
        > div.mce_inline_error {
            @apply .bg-transparent;
            @apply .float-left;
            @apply .font-semibold;
            @apply .p-0;
            @apply .text-gray-800;

            clear: both;
        }
    }

    @screen md {
        @apply .w-2/3;
    }

    @screen lg {
        @apply .w-1/2;
    }
}

#mc_embed_signup_scroll {
    > h2:first-of-type {
        @apply .block;
        @apply .mb-6;
        @apply .text-2xl;
        @apply .text-center;
        @apply .text-gray-900;
    }
}

.mc-field-group {
    input {
        @apply .float-left;
        @apply .leading-normal;
        @apply .mb-6;
        @apply .outline-none;
        @apply .px-4;
        @apply .py-2;
        @apply .rounded-l;
        @apply .w-2/3;

        @screen md {
            @apply .mb-3;
            @apply .rounded-l;
            @apply .rounded-r-none;
            @apply .w-3/4;
        }
    }

    label {
        @apply .hidden;
    }
}

#mc-embedded-subscribe {
    @apply .bg-blue-500;
    @apply .cursor-pointer;
    @apply .leading-normal;
    @apply .px-6;
    @apply .py-2;
    @apply .rounded-r;
    @apply .text-white;
    @apply .w-1/3;

    &:hover {
        @apply .bg-blue-600;
    }

    @screen md {
        @apply .w-1/4;
    }
}
